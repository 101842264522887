import React from 'react'
import { StaticQuery, graphql } from 'gatsby'
import PageTransition from 'gatsby-plugin-page-transitions'

import Layout from '../components/layout'
import Page from '../components/Page'

export const query = graphql`
  query HomeQuery {
    datoCmsPage(root: { eq: true }) {
      title
      alignment
      background {
        url
      }
      treeChildren {
        title
        hotspotX
        hotspotY
        slug
        icon {
          url
        }
        treeChildren {
          title
          slug
        }
      }
    }
    menuData: datoCmsPage(root: { eq: true }) {
      treeChildren {
        displayTitle
        slug
        menuIcon {
          url
        }
        treeChildren {
          displayTitle
          treeChildren {
            displayTitle
            slug
          }
        }
      }
    }
  }
`

export default ({ data, transition }) => (
  <Layout>
    <Page
      transition={transition}
      bg={data.datoCmsPage.background.url}
      align={data.datoCmsPage.alignment}
      hotspots={data.datoCmsPage.treeChildren}
      showHotspotsBorder={false}
      lvl={0}
      menuData={data.menuData}
    />
  </Layout>
)
